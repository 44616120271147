import React, { useState } from "react";
import "./dashboard.scss";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import fitoja_logo from "../assets/fitoja-logo.png";
import fitoja_logo_background from "../assets/fitoja-logo-background.svg";
import googlePlayLogo from "../assets/google-play.png"
import appStoreLogo from "../assets/ios-app-store.png"
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";


const Dashboard = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <div className="dashboard">
            {/* Header */}
            <header className="header">
                <div className="logo">
                    <img src={fitoja_logo} alt="Fitoja Logo" />
                </div>
                <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle navigation">
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </button>
                <nav className={`nav-links ${isMenuOpen ? "open" : ""}`}>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                    <Link to="/about-us">About Us</Link>
                    <Link to="/customer-support">Customer Support</Link>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-content">
                    <h1 className="hero-title">
                        Your <span>Personalized Health</span> and Diet Companion
                    </h1>
                    <p className="hero-description">
                        Fitoja offers an unparalleled experience in managing your health and diet.
                        Dive into holistic solutions tailored to your wellness goals.
                    </p>

                    <div className="cta-buttons">
                        <Link to="https://play.google.com/store/apps/details?id=com.fitoja.fit" target="_blank" rel="noopener noreferrer">
                            <img
                                className="google-play-logo"
                                src={googlePlayLogo}
                                alt="Google Play Logo"
                            />
                        </Link>
                        <Link to="https://apps.apple.com/in/app/fitoja/id6740138984" target="_blank" rel="noopener noreferrer">
                            <img
                                className="app-store-logo"
                                src={appStoreLogo}
                                alt="App Store Logo"
                            />
                        </Link>
                    </div>
                </div>
                <div className="hero-animation">
                    {/* <div className="blob"></div> */}
                    <img src={fitoja_logo} alt="Your Health Journey" height={"250px"}/>
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <h2>Key Features of Fitoja:</h2>
                <div className="feature-cards">
                    <div className="feature-card">
                        <h3>Personalized Diet Plans</h3>
                        <p>Tailored nutrition to meet your unique goals.</p>
                    </div>
                    <div className="feature-card">
                        <h3>BMI & Progress Tracking</h3>
                        <p>Monitor your health journey in real time.</p>
                    </div>
                    <div className="feature-card">
                        <h3>Calorie Tracking</h3>
                        <p>Stay on track with precise calorie management.</p>
                    </div>
                    <div className="feature-card">
                        <h3>Ayurvedic Store</h3>
                        <p>Natural solutions for holistic well-being.</p>
                    </div>
                    <div className="feature-card">
                        <h3>Engaging Health Blogs</h3>
                        <p>Stay informed with insightful health tips.</p>
                    </div>
                    <div className="feature-card">
                        <h3>User-Friendly Interface</h3>
                        <p>An intuitive design for seamless navigation.</p>
                    </div>
                </div>
            </section>

            {/* Why Choose Fitoja Section */}
            <section className="why-choose">
                <h2>Why Choose Fitoja?</h2>
                <p>
                    Fitoja stands out as a holistic health solution that not only emphasizes weight loss
                    but also encourages overall wellness. By combining personalized diet plans with traditional
                    Ayurvedic practices, the app provides users with the tools they need to lead healthier lives.
                </p>
                <p>
                    With a focus on community support and education, Fitoja empowers users to take charge of their
                    health journey, making sustainable lifestyle changes that benefit both body and mind. Whether
                    you're looking to lose weight, manage a health condition, or simply improve your eating habits,
                    Fitoja is your go-to app for achieving your health goals.
                </p>
            </section>

            {/* Footer */}
            <footer className="footer">
                <p>© 2024 - 2025 Fitoja. Designed for a healthier you.</p>
            </footer>
        </div>
    );
};

export default Dashboard;
